
.DocumentList{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  &__header{
    margin: 0 0 28px;
  }
  &__uploadButton{
    width: 30%!important;
    margin-left: auto!important;
    margin-top: 15px!important;
  }
  &__list{
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 0 0 auto;
    &__select{
      flex-basis: 40%;
      margin-right: 30px!important;
    }
  }
  &__dialogModal{
    padding: 20px!important;
    &__title{
    padding: 20px;
    }
    &__content{
    padding: 20px;
    }
    &__actions{
      padding: 10px;
    }&__actions__button{
      width: 250px!important;
    }
  }
}
