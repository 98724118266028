

.RequirementView {
  display: flex;
  flex-direction: row;
  width: 45em;
  background-color: inherit;
  color: inherit;
  align-items: center;
  &__header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -20px;
    &__title{
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;
      padding-bottom: 3px;
      width: 85%;
      border-bottom: 1px solid;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__req {
        display: flex;
      }
      &__est {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
      &__mid {
        color: #333333;
      }
    }
  }
}

.requirement-view {
  &__content{
    font-size: 12px;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 16px;
    > p {
      display: grid;
      word-break: break-word;
      white-space: pre-line;
      > strong {
        margin-right: 10px;
      }
      > span {
        display: inline-flex;
        text-align: justify;
      }
    }
  }
  &__close-button{
    position: absolute;
    top: 0;
    right: 0;
  }
}

.image-view {
  width: 100%;
  height: 280px;
  border: #b7aec8 1px solid;
  position: relative;
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 8px;
  img {
    width: 360px;
    height: 280px;
  }
  &__image{
    max-width: inherit;
    max-height: inherit;
    opacity: 1;
    &__img {
      width: 360px;
      height: 280px;
      opacity: 1;
    }
    &__left-arrow{
      position: absolute;
      top: 40%;
      left: 0;
    }
    &__right-arrow{
      position: absolute;
      top: 40%;
      right: 0;
    }
    &__zoom-button{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .image-view {
    margin: 5px;
    width: 300px;
    height: 233px;
    img {
      width: 300px;
      height: 233px;
    }
  }
  .requirement-view {
    &__content {
      width:  95%;
      > p {
        grid-template-columns: 1fr 2fr;
      }
    }
  }
  .RequirementView {
    flex-direction: column-reverse;
    width: 100%;
    &__header{
      &__title {
        flex-direction: column;
        width: 95%;
        &__est {
          display: flex;
          flex-direction: row-reverse;
          gap: 10px;
        }
        &__req {
          width: 80%;
        }
      }
    }
  }
}
