.DynamicLabel{
  font-size: .8rem!important;
  padding: 0 3rem 0 0!important;
  top: -1.5vh;
}

.DynamicLabel.MuiInputLabel-shrink{
  font-size: .8rem!important;
  white-space: nowrap!important;
}
