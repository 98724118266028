@import '../../scss/variables';

.EditProfile__container {
  width: auto;
  margin: 2rem auto;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);

  .EditProfile__head {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 30px;
    gap: 15px;

    &__avatarLabel {
      padding: 15px;
      border-radius: 50%;
    }

    &__name_container {
      text-align: center;
      font-size: 32px;
      font-weight: 400;

      &__name--almagro{
        color:$almagroTipography;
      }
      &__name--pilares{
        color:#ff6900;
      }
    }
  }

  .EditProfile__button {
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px!important;
    text-transform: none!important;

    &--socovesa{
      color: white;
      background-color: #53308f!important;
    }
    &--almagro{
      color: white;
      background-color: #ee3424!important;
    }
    &--pilares{
      color: white;
      background-color:#ff6900!important;
    }

    &__actualizar{
    
      &--socovesa {
        background-color: $socovesaSecondary;
        color: $lightGrey;
      }
  
      &--almagro {
        background-color: $almagroSecondary;
        color: $lightGrey;
      }
  
      &--pilares {
        background-color: $pilaresSecondary;
        color: $lightGrey;
      }
    }

  }

  .EditProfile__data_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .EditProfile__data {
      .MuiInputBase-root {
        background: #f9f9f9;
        border-radius: 5px;
      }

     
    }
  }

  
}


@media (max-width: 768px) {
  .EditProfile__container {
    padding: 20px;
    margin: 1rem auto;
    box-shadow: none;
  }

  .EditProfile__head {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .EditProfile__head__avatarLabel {
    padding: 5px;
  }

  .EditProfile__head__name_container {
    font-size: 1rem;
  }

  .EditProfile__button {
    width: 100%; 
    padding: 10px; 
    font-size: 0.875rem; 
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      transform: translateY(-2px); 
    }

    &--socovesa {
      background-color: #53308f;
    }

    &--almagro {
      background-color: #ee3424;
    }

    &--pilares {
      background-color: #ff6900;
    }


    &__actualizar{
      
      &--socovesa {
        background-color: $socovesaSecondary;
      }
  
      &--almagro {
        background-color: $almagroSecondary;
      }
  
      &--pilares {
        background-color: $pilaresSecondary;
      }
    }
  }
  


  .EditProfile__data_container {
    gap: 0.5rem;
  }

  .EditProfile__data {
    .MuiInputBase-root {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 1024px) {
  .EditProfile__container {
    padding: 25px;
    margin: 1.5rem auto;
  }

  .EditProfile__head {
    gap: 10px;
  }

  .EditProfile__head__name_container {
    font-size: 1.5rem;
  }

  .MuiButton-contained {
    padding: 5px 10px;
    font-size: 0.5rem;
  }
}
