@import "../../scss/variables";

.Login{
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 80px 0px;
  margin: 0 0px 40px;
  height: 100%;
  &__background{
    height: 100%;
    &__image{
      width: 100%;
      height: 100%;
    }
  }
  &__logo{
    display: block;
    margin: 0 auto;
    &--almagro{
      padding: 80px 0 10%;
    }
    &--socovesa{
      padding: 80px 0 20%;
    }
    &--pilares{
      padding: 80px 0 20%;
    }
    &--empresas{
      padding: 80px 0 20%;
    }
  }
  &__title{
    font-size: 32px;
    margin-bottom: 0;
    font-weight: 300;
    
    &--almagro{
      color: $almagroTipography
    }
    &--pilares{
      color: $white
    }
    &--socovesa{
      color: $white
    }

  }
  &__subtitle{
    margin: 0 0 20px;
    font-size: 32px;
    font-weight: bold;
  }
  &--almagro{
    background-color: $almagroBackground;
    color: $almagroTipography;
  }
  &--pilares{
    background-color: $pilaresBackground;
    color: $white;
  }
  &--socovesa{
    color: $socovesaPrimary;
  }
  &--empresas{
    color: $empresasPrimary;
  }
  &__form{
    &__button{
      display: flex;
      margin: 24px 0;
      justify-content: center;
      align-content: center;
      > * {
        flex-basis: 50%;
      }
    }
  }
}
 
@media only screen and (max-width: 768px) {
  .Login{
    padding: 0 20px 60px;
    &__logo{
      // &--almagro{
      //   padding: 20% 0 15%;
      // }
      // &--socovesa{
      //   padding: 15% 0;
      // }
      // &--pilares{
      //   padding: 15% 0;
      // }
      // &--empresas{
      //   padding: 15% 0;
      // }
    }
    padding: 0 20px 60px;
    &__title{
      &--almagro{
        color:white
      }
    }
    &--almagro{
      background:  url(../../assets/video-almagro.gif) no-repeat center center fixed;
      background-size: cover;
      color:white
    }
    &--pilares{
      color: $white;
    }
    &--socovesa{
      color: $socovesaPrimary;
    }
    &__background{
      display: none;
    }
  }
}
