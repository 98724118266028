@import "../../scss/variables";

.Milestones{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0;
  &__header {
    flex: 2;
    .MuiExpansionPanelSummary-content.Mui-expanded & {
      margin-left: 32px;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    .Milestones__panel__children.Mui-expanded &.MuiExpansionPanelDetails-root {
      padding: 0 32px;
    }
  }
  &__icon {
    align-self: center;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    z-index: 3;
    .Milestones__panel__children.Mui-expanded & {
      height: 90%;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 10px;
    }
    &--socovesa {
      background-color: $socovesaPrimary;
    }
    &--pilares {
      background-color: $pilaresPrimary;
    }
    &--almagro {
      background-color: $almagroPrimary;
    }
    &--empresas{
      background-color: $empresasSecondary;
    }
  }
  &__sub_title {
    &--almagro {
      color: $white;
    }
    &--socovesa {
      color: #676977;
    }
    &.MuiTypography-h5 {
      font-size: 13px;
    }
  }
  &__title {
    align-self: center;
    &--almagro {
      color: #838591;
    }
    &.MuiTypography-h2 {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.13;
      letter-spacing: -0.02px;
      .proxima & {
        color: #838591;
        font-weight: normal;
      }
    }
    &--socovesa{
      color: $socovesaPrimary;
      .finalizada & {
        color: #c6bbda;
        font-weight: normal;
      }
    }
    &--almagro{
      color: #ee3424;
      .finalizada & {
        color: #ba4035;
        font-weight: normal;
      }
    }
    &--pilares{
      color: #ff6900;
      .finalizada & {
        color: #ffc49e;
        font-weight: normal;
      }
    }
    &--empresas{
      color: #0093ca;
      .finalizada & {
        color: #c6bbda;
        font-weight: normal;
      }
    }
  }
  &__date{
    &--almagro{
      color: $almagroTipography;
    }
    &--socovesa{
      color: #546e7a;
    }
    &--pilares{
      color: #676977;
    }
    &--empresas{
      color: #546e7a;
    }
  }
  &__detail{
    margin: 0;
    font-size: 14px;
    &--socovesa{
      color: #546e7a;
    }
    &--almagro{
      color: $almagroTipography;
    }
    &--pilares{
      color: #546e7a;
    }
    &--empresas{
      color: #546e7a;
    }
  }
  &__bottom{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    margin-left: auto;
    &__detailButton{
      display: flex;
    }
  }
  &__panel__children.MuiPaper-root {
    box-shadow: none;
    & .MuiExpansionPanelSummary-root {
      padding-left: 0;
      position: initial;
      &.Mui-expanded {
        min-height: auto;
      }
    }
    &.Mui-expanded {
      margin: 0 0 16px 0;
      & .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 12px 0;
        min-height: auto;

      }
    }
    &:before {
      height: 0;
    }
  }
  &--deactivate{
    &__title{
      &--socovesa{
        color: #c6bbda;
      }
      &--pilares{
        color: #ffc49e;
      }
      &--almagro{
        color: $almagroTipography;
      }
      &--empresas{
        color: #c6bbda;
      }
    }
    &__date{
      &--almagro{
        color: $almagroTipography;
      }
      &--socovesa{
        color: #546e7a;
      }
      &--pilares{
        color: #676977;
      }
      &--empresas{
        color: #bebfc5;
      }
    }
  }
}
