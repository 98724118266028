@import '../../scss/variables.scss';

.ProjectButton {
  display: flex;
  flex-direction: column;
  margin: 0;
  // position: absolute;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__container {
    border-radius: 50%;
    &--almagro {
      color: $almagroTipography;
      &:hover {
        color: $almagroSecondary;
        background-color: $white;
      }
    }
    &--socovesa {
      background-color: $white;
      color: $socovesaPrimary;
      &:hover {
        color: $white;
        background-color: $socovesaPrimary;
      }
    }
    &--pilares {
      &:hover {
        color: $pilaresPrimary;
        background-color: $white;
      }
    }
    &--empresas {
      background-color: $white;
      color: $empresasPrimary;
    }
  }
  &__label {
    margin: 2px 0 0;
    text-align: center;
    font-size: 10px;
    &--almagro {
      color: #a7a7a7;
    }
  }
}
