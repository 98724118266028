@import '../../scss/variables';

.ClientExecutive{
  &__container--socovesa {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    }
  &__container--almagro {
    background-color: $almagroSecondary;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    }
  &__container--pilares {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    }
    &__head {
        display: flex;
        &__avatarLabel{
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1.29;
          letter-spacing: -0.02px;
          color: inherit;
        }
        &__avatar{
          height: 100px;
          width: 100px;
          margin: 0 5px;
          color: inherit;
          border-radius: 50%;
      }
        
        &__name_container{
          display: grid;
          margin-left: 20px;
          &__name--socovesa{
            color:#53308f;
            font-size: 32px;
            letter-spacing: -0.03px;
            margin-top: 20px;
            text-transform: capitalize;
          }
          &__name--pilares{
            color:#ff6900;
            font-size: 32px;
            letter-spacing: -0.03px;
            margin-top: 20px;
            text-transform: capitalize;
          }
          &__name--almagro{
            color:$almagroTipography;
            font-size: 32px;
            letter-spacing: -0.03px;
            margin-top: 20px;
            text-transform: capitalize;
          }
          &__subname--socovesa{
            font-family: "Montserrat";
            color:#4a4d68;
            line-height: 1.13;
            letter-spacing: -0.01px;
            font-size: 16px;
            margin-top: -10px;
          }
          &__subname--pilares{
            font-family: "Montserrat";
            color:#4a4d68;
            line-height: 1.13;
            letter-spacing: -0.01px;
            font-size: 16px;
            margin-top: -10px;
          }
          &__subname--almagro{
            font-family: "Montserrat";
            color:$almagroTipography;
            line-height: 1.13;
            letter-spacing: -0.01px;
            font-size: 16px;
            margin-top: -10px;
          }
        }
    }
    &__descripcion--socovesa{
      width: 340px;
      height: 140px;
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.48px;
      color: #4a4d68;
    }
    &__descripcion--almagro{
      width: 340px;
      height: 140px;
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.48px;
      color: $almagroTipography;
    }
    &__descripcion--pilares{
      width: 340px;
      height: 140px;
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.48px;
      color: #4a4d68;
    }
    &__data_container{
      display: grid;
    }
    &__data{
      font-family: "HelveticaNeue";
      font-size: 10px;
      line-height: 1.8;
      letter-spacing: -0.01px;
      color: #bebfc5;
      margin-bottom: 7px;
    }
    &__data__detail--socovesa{
      line-height: 1.13;
      letter-spacing: -0.02px;
      color: #4a4d68;
      font-family: "HelveticaNeue";
      font-size: 15px;
      margin-bottom: 7px;
    }
    &__data__detail--pilares{
      line-height: 1.13;
      letter-spacing: -0.02px;
      color: #4a4d68;
      font-family: "HelveticaNeue";
      font-size: 15px;
      margin-bottom: 7px;
    }
    &__data__detail--almagro{
      line-height: 1.13;
      letter-spacing: -0.02px;
      color: $almagroTipography;
      font-family: "HelveticaNeue";
      font-size: 15px;
      margin-bottom: 7px;
    }
    &__Line {
      height: 1px;
      background-color: #bebfc5;
      margin-left: 30px;
      margin-right: 30px;
    }

  &__contact {
    display: grid;
    &__title{
      font-family: "HelveticaNeue";
      letter-spacing: -0.02px;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      &--socovesa {
        color: #303446;
      }
      &--pilares{
        color: #303446;
      }
      &--almagro{
        color: $almagroTipography;
      }
    }
    &__text {
      font-family: "HelveticaNeue";
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.48px;
      &--socovesa{
        color: #4a4d68;
      }
      &--pilares{
        color: #4a4d68;
      }
      &--almagro{
        color: $almagroTipography;
      }
    }
    &__button {
      color: #ffffff !important;
      width: 40% !important;
      padding: 10px !important;
      font-weight: bold !important;
      margin-top: 40px !important;
      &--socovesa{
        background-color: #53308f!important;
      }
      &--almagro{
        background-color: #ee3424!important;
      }
      &--pilares{
        background-color:#ff6900!important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ClientExecutive{
    &__head{
      padding: 106px 0 0;
      flex-direction: column;
      align-items: center;
      &__name{
        &_container{
          display: flex;
          flex-direction: column;
          align-items: center;
          &__name{
            font-size: 1.5rem;
            &--almagro{
              color: #ff6900;
            }
            &--socovesa{
              color: #53308f;
            }
            &--pilares{
              color: #ff6900;
            }
          }
          &__subname{
            margin-top: 2px;
            &--almagro{
            }
            &--socovesa{
            }
            &--pilares{
            }
          }
        }
      }
    }
    &__contact{
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title{
        text-align: left;
        align-self: flex-start;
      }
    }
  }
}

