@import "../../scss/variables";

.WorkProgress{
  display: flex;
  flex-direction: column;
  background-color: inherit;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  width: 100%;
  color: inherit;
  &__empty{
    color: inherit;
    padding: 30px;
    border-radius: 4px;
    &--almagro{
      color: $almagroTipography;
    }
  }
  .MuiExpansionPanelSummary-root.Mui-focused {
    background-color: transparent;
  }
  .MuiExpansionPanel-root.Mui-disabled {
    background-color: transparent;
  }
  &--socovesa{
    background-color: $white;
  }
  &--pilares{
    background-color: $white;
  }
  &--almagro{
    background-color: $almagroBackground;
    & .WorkProgress__header .MuiTypography-root {
      color: $almagroTipography;
    }
  }
  &--empresas{
    background-color: $white;
  }
  &__panel__title.MuiTypography-root {
    font-size: 16px;
    font-weight: bold;
    color: #303446;
    .inactivo & {
      color: #b3b3b4;
    }
  }
  &__panel__sub_title.MuiTypography-root {
    font-size: 12px;
    margin-top: 10px;
    color: #303446;
  }
  &__icon {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    background-image: url('../../assets/ico_obra.svg') ;
    background-position: center;
    background-repeat: no-repeat;
    &--socovesa {
      background-color: $socovesaPrimary;
    }
    &--pilares {
      background-color: $pilaresPrimary;
    }
    &--almagro {
      background-color: $almagroPrimary;
    }
    &--empresas{
      background-color: $empresasSecondary;
    }
    .inactivo & {
      background-color: #b3b3b4;
    }
  }
  &__header{
    height: 100%;
    align-self: center;
    flex: 10;
  }
  .MuiPaper-root {
    background: transparent;
  }
  &__panel__parent.MuiPaper-root {
    box-shadow: none;
    & .MuiExpansionPanelDetails-root {
      padding: 0;
    }
    & .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 12px 0;
    }
    & .MuiExpansionPanelSummary-root.Mui-disabled{
      opacity: 1;
    }
    & .MuiExpansionPanelSummary-root {
      min-height: 68px;
    }
    &.Mui-expanded {
      border-bottom: 2px solid #d8d8d8;
      margin: 0;
      &:before {
        opacity: 1;
      }
    }
  }
  &__container{
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    padding-left: 65px;
    position: relative;
    overflow: hidden;
    & .linea {
      position: absolute;
      height: calc(100% - 70px);
      background: red;
      width: 2px;
      z-index: 1;
      right: calc(100% - 72px);
      margin: auto;
      top: 2px;
      bottom: 10px;
      @media only screen and (max-width: 768px) {
        & .linea {
          height: calc(100% - 90px);
        }
      }
      &--socovesa {
        background-color: #eff3f8;
      }
      &--pilares {
        background-color: #ededef;
      }
      &--almagro {
        background-color: $almagroTipography;
      }
      &--empresas{
        background-color: #bebfc5;
      }
    }
    &__body{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      // padding: 0 20px;
    }
    & div:last-child {
      & hr {
        height: 1px;
        background: transparent;
        border: 0;
        border-bottom: solid 1px #ededef;
        width: 88%;
        margin: 0;
        padding: 0;
        float: right;
        margin-right: 5%;
      }
      & > hr {
        border-bottom: 0;
      }
    }
    &:not(:last-child){
      .WorkProgressSimbol{
        &:after{
          z-index: -1;
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
       }
       &--socovesa{
         &:after{
          border-left: 2px solid #bebfc5;
         }
       }
       &--almagro{
        &:after{
          border-left: 2px solid #a7a7a7;
         }
       }
       &--pilares{
        &:after{
          border-left: 2px solid #bebfc5;
         }
       }
       &--empresas{
        &:after{
          border-left: 2px solid #bebfc5;
         }
       }
      }
    }
  }
}
