@import '../../scss/variables';

.white--text{
    color: #fff;
}
.additional-card-almagro{
    background-color: $almagroBackground !important;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 20px;
    color: $almagroTipography!important;
}
.ClientAdditional {
  &__container--socovesa {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  &__container--almagro {
    background-color: $almagroSecondary!important;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  &__container--pilares {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  &__container--empresas {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  &__head {
    display: flex;
    justify-content: space-between;
    ._button_action {
      max-width: 300px;
    }

    &__name_container {
      display: grid;
      margin-left: 20px;
      &__name--socovesa {
        color: #53308f;
        font-size: 32px;
        letter-spacing: -0.03px;
        margin-top: 20px;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      &__name--pilares {
        color: #ff6900;
        font-size: 32px;
        letter-spacing: -0.03px;
        margin-top: 20px;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      &__name--almagro {
        color: $almagroTipography!important;
        font-size: 32px;
        letter-spacing: -0.03px;
        margin-top: 20px;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      &__subname--socovesa {
        font-family: 'Montserrat';
        color: #4a4d68;
        line-height: 1.13;
        letter-spacing: -0.01px;
        font-size: 16px;
      }
      &__subname--pilares {
        font-family: 'Montserrat';
        color: #4a4d68;
        line-height: 1.13;
        letter-spacing: -0.01px;
        font-size: 16px;
      }
      &__subname--almagro {
        font-family: 'Montserrat';
        color: $almagroTipography!important;
        line-height: 1.13;
        letter-spacing: -0.01px;
        font-size: 16px;
      }
    }
  }
  &__descripcion--socovesa {
    width: 340px;
    height: 140px;
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.48px;
    color: #4a4d68;
    &.__center{
      text-align: center;
    }
    &.commnet-variant{
      letter-spacing: 0.48px;
      font-style: italic;
      color: #cbcbcb;
    }
  }
  &__descripcion--almagro {
    width: 340px;
    height: 140px;
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.48px;
    color: $almagroTipography!important;
    &.__center{
      text-align: center;
    }
    &.commnet-variant{
      letter-spacing: 0.48px;
      font-style: italic;
      color: #cbcbcb;
    }
  }
  &__descripcion--pilares {
    width: 340px;
    height: 140px;
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.48px;
    color: #4a4d68;
    &.__center{
      text-align: center;
    }
    &.commnet-variant{
      letter-spacing: 0.48px;
      font-style: italic;
      color: #cbcbcb;
    }
  }
  &__data_container {
    display: grid;
  }
  &__data {
    font-family: 'HelveticaNeue';
    font-size: 10px;
    line-height: 1.8;
    letter-spacing: -0.01px;
    color: #bebfc5;
    margin-bottom: 7px;
  }
  &__data__detail--socovesa {
    line-height: 1.13;
    letter-spacing: -0.02px;
    color: #4a4d68;
    font-family: 'HelveticaNeue';
    font-size: 15px;
    margin-bottom: 7px;
  }
  &__data__detail--pilares {
    line-height: 1.13;
    letter-spacing: -0.02px;
    color: #4a4d68;
    font-family: 'HelveticaNeue';
    font-size: 15px;
    margin-bottom: 7px;
  }
  &__data__detail--almagro {
    line-height: 1.13;
    letter-spacing: -0.02px;
    color: $almagroTipography!important;
    font-family: 'HelveticaNeue';
    font-size: 15px;
    margin-bottom: 7px;
  }
  &__Line {
    height: 1px;
    background-color: #bebfc5;
    margin-left: 30px;
    margin-right: 30px;
  }

  &__contact {
    display: grid;
    &__title {
      font-family: 'HelveticaNeue';
      letter-spacing: -0.02px;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      &--socovesa {
        color: #303446;
      }
      &--pilares {
        color: #303446;
      }
      &--almagro {
        color: $almagroTipography!important;
      }
    }
    &__text {
      font-family: 'HelveticaNeue';
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.48px;
      &--socovesa {
        color: #4a4d68;
      }
      &--pilares {
        color: #4a4d68;
      }
      &--almagro {
        color: $almagroTipography!important;
      }
    }
    &__button {
      color: #ffffff !important;
      width: 40%;
      padding: 10px !important;
      font-weight: bold !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
      &--socovesa {
        background-color: #53308f !important;
      }
      &--almagro {
        background-color: #ee3424 !important;
      }
      &--pilares {
        background-color: #ff6900 !important;
      }
      &--empresas {
        background-color: #0093ca !important;
      }
    }
  }
}

.status-switch {
  .Mui-checked {
    color: green;
  }
}
@media only screen and (max-width: 768px) {
  .ClientAdditional {
    &__head {
      padding: 106px 0 0;
      flex-direction: column;
      align-items: center;
      &__name {
        &_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          &__name {
            font-size: 1.5rem;
            &--almagro {
              color: #ff6900;
            }
            &--socovesa {
              color: #53308f;
            }
            &--pilares {
              color: #ff6900;
            }
          }
          &__subname {
            margin-top: 2px;
            &--almagro {
            }
            &--socovesa {
            }
            &--pilares {
            }
          }
        }
      }
    }
    &__contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        text-align: left;
        align-self: flex-start;
      }
      &__button {
        width: 100%;
      }
    }
    &__desktop {
      display: none;
    }
    &__mobile{
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ClientAdditional {
    &__desktop {
      display: flex;
    }
    &__mobile{
      display: none;
    }
  }
}

.selector {
  .MuiSelect-select.MuiSelect-selectMenu {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}
