.ClientStatus{
  display: flex;
  flex-direction: row;
  &__errorMessage{
    font-style: italic;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-flow: row wrap;
  gap: 0.5rem;
}
