@import "../../scss/variables";

.ClientActivation{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  font-family: 'Montserrat';
  
  height: 100%;
  &--socovesa {
    h1{
      color: $socovesaPrimary;
    }
    background-color: $white;
  }
  &--almagro {
    h1{
      color: $almagroPrimary;
    }
    color: $white;
    background-color: $almagroSecondary;
  }
  &--pilares {
    h1{
      color: $pilaresPrimary;
    }
    background-color: $white;
  }
}

.ActivationMessage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  border-radius: 4px;
  &__logo{
    margin: 40px 0 0;
  }
}
