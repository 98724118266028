.MultipleUpload{
  padding: 20px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  &__dialog{
    padding: 30px;
    &__action{
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 30px auto;
    }
  }
  &__input{
    padding: 10px;
  }
  &__title{
    padding: 0px 10px 20px;
  }
  &__submitButton{
    display: flex;
    justify-content: space-around;
    padding: 30px 0 15px;
  }
}

.MultipleUploadList{
  margin: 20px 0 0;
  padding: 22px 21px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  &__list{
    list-style-type: none;
    padding: 0;
  }
}
