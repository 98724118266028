@import '../../scss/variables';

.shadow-readed{
    opacity: 0.5;
}

.LastMessages{
    height: auto;
    border-radius: 4px;
    box-shadow: 2px 4px 0 rgba(103, 105, 119, 0.4);
    &__item__readed{
        cursor: pointer;
        padding: 15px;
        border-bottom: 1px solid  rgba(103, 105, 119, 0.4);
        &--socovesa{
            background-color: #f4f4f5;
        }
        &--pilares{
            background-color: #f4f4f5;
        }
        &--almagro{
            background-color: $almagroBackground;
        }
    }
    &__item{
        cursor: pointer;
        padding: 15px;
        border-bottom: 1px solid  rgba(103, 105, 119, 0.4);
        &--socovesa{

        }
        &--pilares{

        }
        &--almagro{
            background-color: $almagroBackground;
        }
    }
        &__container{

    }

        &__circle{
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin: 3px 10px 0px 0px;
        background-color: #53308f;
            &--sovocesa{
            background-color: #53308f;
        }
            &--almagro{
            background-color: #ee3424;
        }
            &--pilares{
            background-color: #ff6900;
        }
    }
        & __name{
        text-transform: lowercase;
        text-transform: capitalize;
        font-family: "HelveticaNeue";
        font-weight: bold;
        font-size: 16px;
          &--sovocesa{
            color: #303446;
        }
          &--pilares{
            color: #303446;
        }
          &--almagro{
            color: $almagroTipography;
        }
    }
        &__asunto{
        text-transform: lowercase;
        font-family: "HelveticaNeue";
        font-size: 15px;
        display: flex;
        align-self: center;
        flex-direction: column;
        margin-left: 9px;
          &--socovesa{
            color: #303446;
        }
          &--pilares{
            color: #303446;
        }
          &--almagro{
            color: $almagroTipography;
        }
    }
        &__fecha{
        color: #acacac;
        display: grid;
        padding-left: 2%;
          &__container{
            padding-top: 0!important;
            padding-right: 0!important;
            margin-top: -5px!important;
            font-size: 13px!important;
            text-transform: uppercase!important;
        }
    }

}