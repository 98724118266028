@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');

.SubsidiaryScreenList {
    display: grid;
    gap: 10px;
    padding: 30px;
    &__title {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.03px;
        color: #003a63;
        margin: 0 0 25px;
        padding-bottom: 20px;
    }
}