.ForgotPassword{
  display: flex;
  flex-direction: column;
  padding: 0 80px 0px;
  margin: 0 0px 40px;
  color: #303446;
  height: 100%;
  &--almagro{
    color:black;
    background-color: white;
  }
  &__header{
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 80px 0 150px;
    align-content: center;
    justify-content: center;
    &__arrow{
      display: flex;
      position: absolute;
      left: 0;
      justify-content: flex-start;
      align-self: center;
    }
    &__logo{
      display: flex;
      justify-self: center;
      align-self: center;
    }
  }
  &__title{
    font-weight: 400;
  }
  &__form{
    display: flex;
    flex-direction: column;
    &__buttons{
      display: flex;
      margin: 20px 0 0;
      align-content: center;
      justify-content: center; 
    }
  }
  &__passwordSend{
    display: flex;
    flex-direction: column;
    text-align: center;
    &__header{
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin: 0 0 20px;
      &__icon{
        display: flex;
        align-self: center;
        margin: 0 0 10px;
      }
    }
    &__span{
      color:inherit;
      &:last-of-type{
        margin-bottom: 30px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ForgotPassword{
    padding: 0 20px 60px;
  }
}
