.ProjectItemMultiple{
  width: 100%;
  margin: 15px 0 0!important;
  &__summary{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__button{
    position: initial;
    top: initial;
  }
  .MuiExpansionPanelDetails-root{
    flex-direction: column;
  }
  .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 768px) {
  .ProjectItemMultiple{
    &__summary{
      flex-direction: column;
      * > {
        margin: 10px 0;
      }
    }
  }
}
