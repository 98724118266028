.MultipleUploadItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: solid 1px #b7aec8;
  padding: 15px 25px;
  font-size: 12px;
  &:not(:first-child) {
    margin-top: 10px;  
  }
  &__icon{
    margin-right: 15px;
  }
  &__name{
    font-weight: bold;
    text-transform: uppercase;
  }
  
}
