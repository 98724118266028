.SwitchButton {
  display: flex;
  &__icon {
    margin: 5px;
    cursor: pointer;
    &__no-select {
      opacity: 0.6;
    }
  }
}
