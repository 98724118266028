@import '../../scss/variables';

.ClientMessages {
  &--almargro {
    color: $white;
  }
  &--socovesa {
    color: #000000;
  }
  &--pilares {
    color: #000000;
  }
  &__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
  }
  &__searchBar{
    margin: 0 0 50px;
  }
  &__item{
    margin: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .ClientMessages{
    padding: 80px 10px 0;
    max-width: 95vw;
    &__searchBar{

    }
  }
}
