.MenuComponent {
  // padding: 0 25px;
  height: 100%;
  &__Container {
    &__Section {
      &__name {
        color: '#546e7a';
        font-size: 1rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: 4px;
        padding: 0px 24px;
        &--socovesa {
          color: #ffffff;
        }
        &--almagro {
          color: #546e7a;
        }
        &--pilares {
          color: #546e7a;
        }
        &--empresas {
          color: #546e7a;
        }
      }
      &__itemList {
        list-style-type: none;
        padding: 0 0 60px;
        &__item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          margin: 0 0 20px;
          text-transform: uppercase;
          // min-width: 300px;
          text-decoration: none;
          transition: 0.2s all ease;
          cursor: pointer;
          border-radius: 15.5px;
          padding: 7px 24px;
          outline: none;
          &--socovesa {
            color: #ffffff;
            &.active {
              background-color: #53308f;
              color: #ffffff;
            }
          }
          &--almagro {
            color: #4D4D4D;
            &.active {
              background-color: #ee3424;
              color: #ffffff;
            }
          }
          &--pilares {
            color: #ffffff;
            &.active {
              background-color: #ff6900;
            }
          }
          &--empresas {
            color: #b8b7ad;
            &.active {
              color: #ffffff;
              background-color: #0093ca;
            }
          }
          &__icon {
            // margin-left: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .MenuComponent {
    &__Container {
      &__Section {
        &__name {
          &--socovesa {
            color: #546e7a;
          }
          // &--almagro {
          //   color: #546e7a;
          // }
          // &--pilares {
          //   color: #546e7a;
          // }
          // &--empresas {
          //   color: #546e7a;
          // }
        }
        &__itemList {
          padding: 0 0 30px;
          &__item {
            &--socovesa {
              color: #546e7a;
            }
            &--almagro {
              
            }
            &--pilares {
              
            }
            &--empresas {
            }
          }
        }
      }
    }
  }
}
