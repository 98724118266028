.ImageUpload{
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  background-color: #ffffff;
  padding: 20px;
  &__uploadButton{
    display: flex!important;
    width: 90%!important;
    margin: auto!important;
    align-self: center!important;
  }
  &__action{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    >  * {
      margin-left: 20px!important;
    }
  }
  &__preview{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
    &__img{
      border-radius: 8px;
    }
    &__description{
      align-self: flex-start;
      margin: 8px 0;
    }
  }
  &__listContainer{
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    background-color: inherit;
    padding: 15px 20px;
    margin: 20px 0;
    &__list{
      padding: 0;
      margin: 15px 0;
      list-style: none;
      &__item{
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        border: solid 1px #b7aec8;
        background-color: inherit;
        align-items: center;
        &:not(:last-child){
          margin: 0 0 20px;
        }
        &__img{
          width: 60px;
          height: 60px;

        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ImageUpload{
    .MuiFormControl-root{
      margin: 0 0 20px;
    }
  }
}
