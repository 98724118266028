.MessageForm {
  display: flex;
  &__form {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    &__input {
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .MessageForm{
    &__form {
      padding:0 5vh;
      &__buttons {
        flex-direction: column;
        > * {
          margin: 0 0 20px!important;
        }
      }
    }
  }
}
