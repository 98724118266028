@import '../../scss/variables';

.SubsidiaryCardItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding:10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--invalid-name);
  white-space: nowrap;
  &__title {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px;
  }
  &__checkbox {
    display: flex;
    flex-direction: column;
    gap:10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  &__responsive{
    display: none;
  }
  &__saveButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px;
    &--almagro {
      color: $white;
    }
    &--socovesa {
      color: $black;
    }
    &--pilares {
      color: $black;
    }
  }
}