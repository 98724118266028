.MilestoneCalendar{
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  &__container{
    display: flex;
    flex-direction: row;
  }
  &__scrollable{
    height: 150px;
    overflow-y: scroll;
  }
  &__items_head{
    padding: 10px 40px 30px 0px;
    margin-bottom: 30px;
    border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
    text-align: center;
     &__item{
      padding: 10px !important;
  }
  }
  &__items{
    margin-top: 10px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    &__container{
      
    }
    &__doc_text{
      text-transform: uppercase;
    }
    &__cols{
      text-align: left;
      padding-left: 60px;
    }
  }
  &__arrows{
      display: flex;
      align-items: center;
      color: inherit !important;
      text-transform: inherit !important;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.02px;
      text-align: right;
  }

    &__footer{
    padding: 23px 0px;
    margin-left: auto;
    display: flex;
    justify-content: end;
    &__addMoreLabel{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__icon{
        margin-left: 7px;
      }
    }
  }


}

@media only screen and (max-width: 768px) {
  .MilestoneCalendar{
    &__items{
      
    }
  }
}


