@import '../../scss/variables';

.ExecutiveListItem {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative;
  display: flex;
  padding: 15px 0;
  margin-bottom: 10px;
  & .item {
    flex: 3;
    font-size: 16px;
    margin-right: 100px;
    color: #303446;
    flex-grow: 2;
    &:nth-of-type(1) {
      padding-left: 44px;
      flex-grow: 5;
    }
  }
  & .ProjectButton__wrapper.ProjectItem__header__button {
    cursor: pointer;
    position: static;
    right: inherit;
    top: inherit;
    width: 50px;
    margin-right: 20px;
    color: #003a63;
  }
  &__body {
    &__image {
      width: 100%;
    }
  }
  &__footer {
    padding: 20px;
    &__label {
      display: block;
      margin: 0px 0px 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.01px;
    }
  }
}
