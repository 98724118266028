@import "../../scss/variables";

.ClientPropertyDetail{
  &__greeting {
    font-size: 18px;
    letter-spacing: -0.02px;
    &--socovesa {
      color: $lightGrey;
    }
    &--almagro {
      color: $lightGrey;
    }
    &--pilares {
      color: $lightGrey;
    }
  }
  &__detail{
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 15px 0;
    width: 45%;
    border-radius: 15px;
    &__item{
      display: flex;
      flex-direction: column;
      margin: 0px 0 10px;
      &__name{
        font-weight: bold;
      }
    }
    &--almagro{
      background-color: $almagroSecondary;
      color: black;
    }
    &--socovesa {
      background-color:#ffffff;
    }
    &--pilares {
      background-color:#ffffff;
    }
  }
  &__ProjectName{
    &--almagro{
      color: #ffffff!important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ClientPropertyDetail{
    padding: 100px 15px;
    max-width: 95vw;
    &__detail{
      width: 90%;
      &__item{
        display: block;
        padding: 5px;        

        & > span {
          display: inline-block; 
          vertical-align: middle; 
          margin-right: 10px; 
        }
      
      }
    }
  }
}
