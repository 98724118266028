.EnterpriseLayout {
  // min-height: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  margin: 0;
  overflow: auto;
  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #003a63;
    min-height: 100%;
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 60px 0 0;
      &__logo {
        margin: auto auto 100px;
      }
    }
  }
  &__body {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .EnterpriseLayout {
    &__sidebar {
      display: none;
    }
  }
}
