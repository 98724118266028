.AlphabeticalFilter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px;
  &__letter{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.02px;
    color: #003a63;
    padding: 7.5px;
    &--empresas{
      &--active{
        color: #0093ca;
        border-bottom: 2px solid #0093ca;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .AlphabeticalFilter{
    flex-direction: column;
    position: absolute;
    right: 0px;
  }
}
