.Searchbar {
  &__input{
    width: 100%;
    border-radius: 5px;
    border: solid 1px #bebfc5;
    background-color: #ffffff;
  }
  &__button {
    display: flex !important;
    align-items: center !important;
    margin-left: auto !important;
  }
}


@media screen and (max-width: 768px) {
  .Searchbar {
    &__input{
      max-width: 95vw;
    }
  }
}