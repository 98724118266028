.EnterpriseHome {
  height: 100%;
  &__container {
    padding: 25px 30px;
    position: relative;
    &__title {
      color: #003a63;
      font-size: 32px;
      font-family: Montserrat;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      color: #003a63;
      text-transform: uppercase;
    }
    &__messageLabel {
      display: block;
      color: #acacac;
      font-size: 18px;
      letter-spacing: -0.02px;
      margin: 0 0 10px;
    }
    &__messages {
      width: 100%;
    }
    &__subtitle {
      margin: 55px 0 20px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.02px;
    }
    &__table {
      margin: 30px 0 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .EnterpriseHome {
    &__container {
      padding: 80px 15px 20px;
      &__executiveSelector{
        margin: 0 auto 50px;
        position: initial;
        max-width: 80%;
      }
    }
  }
}
