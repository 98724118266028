@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');

.DocumentsClassification {
    display: grid;
    gap: 10px;
    padding: 30px;
    &__title {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.03px;
        color: #003a63;
        margin: 0 0 25px;
        padding-bottom: 20px;
    }
    
    &__container {
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 4px;
        box-sizing: border-box;
        box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    }
}
