.ClientDetail{
  padding: 25px 30px;
  &__title{
    font-size: 32px;
    font-family: Montserrat;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0;
  }
  &__subtitle{
    margin: 0 0 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    text-transform: uppercase;
    color: #acacac;
  }
  &__info{
    padding: 36px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    &__title{
      margin: 0 0 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-align: left;
      color: #003a63;
      text-transform: uppercase;
    }
    &__list{
      list-style-type: none;
      text-align: left;
      padding: 0;
      &__label{
        line-height: 1.29;
        letter-spacing: -0.02px;
        color: #bebfc5;
      }
      &__value{
        margin: 0 0 10px;
      }
      &__errorMessage{
        font-style: italic;;
      }
    }
  }
  .ClientProjects {
    width: 100%;
    .ClientProject {
      box-shadow: 'none';
      margin-bottom: 1rem;
      border-radius: 5px;
    }
  }

  .ClientProperties, .ClientDocuments {
    width: 100%;
    @extend .ClientProjects;
    .ClientProperty {
      @extend .ClientProject;
    }
    .filter {
      display: flex;
      justify-content: end;
    }
  }

  .MuiExpansionPanelSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BusinessStatusButton {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    position: relative;
    padding: 15px 35px!important;
    margin: 0px 0 0px 20px!important;
    &:hover{
            g{
              fill: white;
            }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ClientDetail{
    .MuiGrid-spacing-xs-6{
      width: auto;
      margin: 0;
    }
    padding: 100px 15px;
  }
}
