.MilestoneCalendarForm{
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  &__container{
    display: flex;
    flex-direction: row;
  }
  &__form {
    &__body {
        padding: 0px 40px 40px 40px;
    }
    &__input {
        width: 100%;
    }
    &__label {
        color: rgb(103, 105, 119);
    }
    &__buttons {
      display: flex !important;
      justify-content: space-between !important;
    }
    &__button {
        margin-left: 15px !important;
    }
  }
  &__items_head{
    padding: 10px 50px;
    margin-bottom: 30px;
    border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
    text-align: center;
  }
  &__arrows{
      display: flex;
      align-items: center;
      color: inherit !important;
      text-transform: inherit !important;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.02px;
      text-align: right;
  }

    &__footer{
    padding: 23px 0px;
    margin-left: auto;
    float: right;
    &__addMoreLabel{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__icon{
        margin-left: 7px;
      }
    }
  }



}

@media only screen and (max-width: 768px) {
  .MilestoneCalendarForm{
    &__items_head{
      padding: 10px;
    }
    &__footer{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &__form{
      &__buttons{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        .MuiButtonBase-root{
          width: 100%;
          margin:  0 0 20px;
        }
      }
      &__body{
        padding: 0;
        .MuiGrid-spacing-xs-10{
          padding: 10px;
        }
    }
  }
  }
}
