@import '../../scss/variables';

.Loader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background:rgba(255,255,255,0.5);
  z-index: 100;
  position: relative;
  &__text{
    bottom: 30%;
    position: absolute;
  }
  &__animation{
    max-width: 30%;
    &--empresas{
      color: $empresasSecondary;
      @-webkit-keyframes empresas-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 0, 0);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 0, 0);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-1 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 2.7s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 2.7s both;
      }
      
      .svg-elem-2 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.2s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.2s both;
      }
      
      .svg-elem-3 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.30000000000000004s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.30000000000000004s both;
      }
      
      .svg-elem-4 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.4s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.4s both;
      }
      
      .svg-elem-5 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.5s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.5s both;
      }
      
      .svg-elem-6 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.6s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.6s both;
      }
      
      .svg-elem-7 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.7000000000000001s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.7000000000000001s both;
      }
      
      .svg-elem-8 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.8s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.8s both;
      }
      
      .svg-elem-9 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.9s both;
                animation: empresas-svg-fill-letter 2.7s linear infinite 0.9s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-2  {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 58, 99);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-2 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 58, 99);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-10 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1s both;
      }
      
      .svg-elem-11 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.1s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.1s both;
      }
      
      .svg-elem-12 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.2000000000000002s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.2000000000000002s both;
      }
      
      .svg-elem-13 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.3000000000000003s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.3000000000000003s both;
      }
      
      .svg-elem-14 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.4000000000000001s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.4000000000000001s both;
      }
      
      .svg-elem-15 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.5000000000000002s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.5000000000000002s both;
      }
      
      .svg-elem-16 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.6s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.6s both;
      }
      
      .svg-elem-17 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.7000000000000002s both;
                animation: empresas-svg-fill-2 2.7s linear infinite 1.7000000000000002s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-3 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(254, 254, 254);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-3 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(254, 254, 254);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-18 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 1.8000000000000003s both;
                animation: empresas-svg-fill-3 2.7s linear infinite 1.8000000000000003s both;
      }
      
      .svg-elem-19 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 1.9000000000000001s both;
                animation: empresas-svg-fill-3 2.7s linear infinite 1.9000000000000001s both;
      }
      
      .svg-elem-20 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2s both;
                animation: empresas-svg-fill-3 2.7s linear infinite 2s both;
      }
      
      .svg-elem-21 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2.1s both;
                animation: empresas-svg-fill-3 2.7s linear infinite 2.1s both;
      }
      
      .svg-elem-22 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2.2s both;
                animation: empresas-svg-fill-3 2.7s linear infinite 2.2s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-4 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(139, 165, 183);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-4 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(139, 165, 183);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-23 {
        -webkit-animation: empresas-svg-fill-4 2.7s linear infinite 2.3000000000000003s both;
                animation: empresas-svg-fill-4 2.7s linear infinite 2.3000000000000003s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-5 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(101, 136, 160);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-5 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(101, 136, 160);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-24 {
        -webkit-animation: empresas-svg-fill-5 2.7s linear infinite 2.4000000000000004s both;
                animation: empresas-svg-fill-5 2.7s linear infinite 2.4000000000000004s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-6 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(63, 107, 137);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-6 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(63, 107, 137);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-25 {
        -webkit-animation: empresas-svg-fill-6 2.7s linear infinite 2.5000000000000004s both;
                animation: empresas-svg-fill-6 2.7s linear infinite 2.5000000000000004s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-7 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(24, 76, 113);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-7 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(24, 76, 113);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-26 {
        -webkit-animation: empresas-svg-fill-7 2.7s linear infinite 2.6s both;
                animation: empresas-svg-fill-7 2.7s linear infinite 2.6s both;
      }
      
      @-webkit-keyframes empresas-svg-fill-8 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 58, 99);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes empresas-svg-fill-8 {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(0, 58, 99);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-27 {
        -webkit-animation: empresas-svg-fill-8 2.7s linear infinite 2.7s both;
                animation: empresas-svg-fill-8 2.7s linear infinite 2.7s both;
      }
      
    }
    &--socovesa{
      color: $socovesaPrimary;
      @-webkit-keyframes socovesa-svg-square {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(75, 49, 140);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes socovesa-svg-square {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(75, 49, 140);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-1 {
        -webkit-animation: socovesa-svg-square 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.8s both;
                animation: socovesa-svg-square 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.8s both;
      }
      
      @-webkit-keyframes socovesa-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(86, 90, 108);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes socovesa-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(86, 90, 108);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-2 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.9s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.9s both;
      }
      
      .svg-elem-3 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1s both;
      }
      
      .svg-elem-4 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.1s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.1s both;
      }
      
      .svg-elem-5 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.2s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.2s both;
      }
      
      .svg-elem-6 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.3s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.3s both;
      }
      
      .svg-elem-7 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.4s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.4s both;
      }
      
      .svg-elem-8 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.5s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.5s both;
      }
      
      .svg-elem-9 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.6s both;
                animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.6s both;
      }
      
    }

    &--pilares{
      color: $pilaresPrimary;
      @-webkit-keyframes pilares-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(255, 107, 0);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes pilares-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(255, 107, 0);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      .svg-elem-1 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
      }
      
      .svg-elem-2 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
      }
      
      .svg-elem-3 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
      }
      
      .svg-elem-4 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6000000000000001s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6000000000000001s both;
      }
      
      .svg-elem-5 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
      }
      
      .svg-elem-6 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
      }
      
      .svg-elem-7 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9000000000000001s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9000000000000001s both;
      }
      
      .svg-elem-8 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
                animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
      }
      
    }

    &--almagro{
      color: $almagroPrimary;
      @-webkit-keyframes almagro-svg-fill-letter {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(255, 255, 255);
        }
      
        100% {
          fill: transparent;
        }
      }
      
      @keyframes almagro-svg-fill-letter{
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(255, 255, 255);
        }
      
        100% {
          fill: transparent;
        }
      }

      @-webkit-keyframes almagro-svg-fill-square {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(238, 52, 36);
        }

        100% {
          fill: transparent;
        }
      }
      
      @keyframes almagro-svg-fill-square {
        0% {
          fill: transparent;
        }
      
        50% {
          fill: rgb(238, 52, 36);
        }

        100% {
          fill: transparent;
        }
      }

      .svg-elem-square{
        -webkit-animation: almagro-svg-fill-square  2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0s both;
                animation: almagro-svg-fill-square  2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0s both;
      }
      
      .svg-elem-1 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
      }
      
      .svg-elem-2 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
      }
      .svg-elem-3 {
        -webkit-animation: almagro-svg-fill-letter  2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
                animation: almagro-svg-fill-letter  2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
      }
      
      .svg-elem-4 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both;
      }
      
      .svg-elem-5 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
      }
      
      .svg-elem-6 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
      }
      
      .svg-elem-7 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both;
      }
      
      .svg-elem-8 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
      }

      .svg-elem-9 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1.1s both;
                animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1.1s both;
      }
      
    }
    
  }
}

@media only screen and (max-width: 768px) {
  .Loader{
    &__animation{
      max-width: 70%;
      &--almagro{
        width: 30%;
      }
    }
  }
}
