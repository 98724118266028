@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

// Base styling for the dialog
.PermissionDialog {
background-color: #f4f4f8;
padding: 20px;
border-radius: 10px;

&__title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;
    text-align: center;
}

&__subtitle {
    color: #666;
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
}
}

// Common styling for permissions sections
.ProfilePermissions,
.OtherPermissions {
margin-bottom: 20px;
padding: 20px;

&__title {
    color: #444;
    font-size: 16px;
    margin-bottom: 15px;
    padding-top: 0.5rem;
    margin-top:0%;
}

// Grid layout for checkboxes
&__checkbox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // Adjust minmax as needed
    gap: 10px;
}

.permissionCheckbox {
    accent-color: #007bff; // Checkbox color
}
}

// Specific styling for ProfilePermissions
.ProfilePermissions {
background-color: #e8eaf6; // Lighter shade for distinction
border-radius: 8px;
    &__title {
        color: #202853;
    }
}

// Specific styling for OtherPermissions
.OtherPermissions {
background-color: #f0f0f4; // Slightly different shade
border-radius: 8px;
}
