.ClientList{
  padding: 25px 30px;
  &__title{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 768px) {
  .ClientList{
    padding: 100px 30px 0px 10px;
  }
}
