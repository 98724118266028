.NewMessageForm {
  &__form {
    &__input {
    }
    &__buttons {
      display: flex !important;
      justify-content: space-between !important;
      margin: 20px 0 0;
    }
    &__fileUpload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__file {
        display: flex;
        &__link {
          text-decoration: none;
          :visited {
            color: inherit;
          }
        }
        &__icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.toChip{
  padding: 7px 15px;
  border-radius: 18px;
  background-color: #ededef;
}

@media only screen and (max-width: 768px) {
  .NewMessageForm {
    &__form {
      &__input {
      }
      &__buttons {
        flex-direction: column-reverse;
        > * {
          margin: 0 0 20px!important;
        }
      }
    }
  }
}
