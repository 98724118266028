.NoteItem{
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  &__container{
    display: flex;
    flex-direction: row;
    &__info{
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      &__date{
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.02px;
        margin: 0 0 5px;
      }
      &__title{
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.02px;
        color: #1f4b7e
      }
    }
    &__buttons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      &__withlabel{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      button{
        display: flex!important;
        margin: 0 10px 0;
        &:last-child{
          display: flex!important;
          margin: 0 0 0 10px;
      }
      }
      &__arrowButton{
        color: #0093ca;
        &--active{
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
  }
  &__collapse{
    margin: 13px 0 0;
    overflow: scroll;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.02px;
    color: #383b50;
  }
}
