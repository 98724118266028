@import '../../scss/variables';

.ClientLayout {
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: absolute;
  &--socovesa {
    background-color: $socovesaSecondary;
  }
  &--almagro {
    background-color: $almagroBackground;
  }
  &--pilares {
    background-color: $pilaresSecondary;
  }
  &__sideMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &--socovesa {
      color: $white;
      background-color: $socovesaMenu;
    }
    &--almagro {
      color: $white;
      background-color: $almagroSecondary;
    }
    &--pilares {
      color: $white;
      background-color: $pilaresBackground;
    }
    &__logo {
      margin: 60px 0 0;
    }
    &__Menu {
      margin: 100px 0 0;
    }
  }
  &__body {
    padding: 25px 30px;
    background-color: inherit;
  }
}

@media only screen and (max-width: 959px) {
  .ClientLayout {
    &__Menu {
      display: none;
    }
    &__body {
      padding: 0px;
    }
  }
}
