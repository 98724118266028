@import '../../scss/variables';

.RolesCardItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 20px;
  padding:10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  &__header {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
  }
  &__footer {
    display: flex;
    align-items: center; 
    gap:15px;
    padding-top: 20px;
  }
  &__title {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0; 
    flex: 1;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; 
    padding-top: 10px;
  }
  &__checkbox {
    display: flex;
    flex-direction: row;
    flex-grow: 10;
    gap:10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  &__responsive{
    display: none;
  }
}