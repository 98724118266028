.EnterpriseMessageList {
  padding: 25px 30px;
  &__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px;
  }
  &__searchbar {
    margin: 0 0 20px;
  }
  &__tabs {
    margin: 0 0 30px;
  }
  &__item{
    margin: 0 0 15px;
  }
  &__addMoreRows{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__label{
      display: flex;
      align-items: center;
    }
    &__icon{
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .EnterpriseMessageList {
    padding: 100px 10px;
  }
}
