.NoteList{
  display: flex;
  flex-direction: column;
  &__container{
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    &__list{
      margin: 0;
      list-style-type: none;
      overflow-y: auto;
      padding: 0;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    }
  }
  &__form{
    padding: 10px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    &__buttons{
      display: flex!important;
      justify-content: space-between;
    }
  }
  &__buttonContainer{
    margin:  20px 0 0 auto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.02px;
    &__button{
      display: flex;
      align-items: center;
      &__component{
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .NoteList{
    &__container{
      width: 100%;
    }
    &__form{
      &__buttons{
        flex-direction: column;
        .MuiButtonBase-root {
          margin: 0 0 20px ;
        }
      }
    }
  }
}
