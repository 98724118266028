@import '../../../../scss/variables';

.AdditionalList{
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  &__container{
    display: grid;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
  }
  &__expansionPanelDetails{
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px;
  }
}

.AdditionalDetail {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  // padding: 0px 30px;
  &__expansionPanelSummary{
    display: inline-grid;
    &__estado-solicitud {
      display: inline;
    }
    &__solicitud-data {
      display: flex;
      flex-direction: column;
      &__one {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
    }
      &__barra-tareas {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 0.75rem;
        .warning {
          color: red;
        }
      }
      &__addressName{
        font-size: 12px;
        color:#8e8f96;
        margin-bottom: 6px;
        font-weight: bold;
        margin-left: 34px;
        text-transform: none !important;
      }
      &__AddName{
        font-size: 14px;
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        span {
          font-size: 12px;
          color: #8e8f96;
          font-weight: normal;
          margin-left: 5px;
        }
        &__ProyD {
          display: inline-block;
        }
        &__ProyM {
          display: none;
        }
        &__Nro {
          display: inline-block;
        }
      }
      &__Icono{
        margin-right: 10px;
      }
  }
  &__expansionPanelDetails{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px 32px 0px;
    &__botones {
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between;
    }
    &__table{
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 4fr 3fr 2fr 5fr 2fr;
      width: 100%;
      border-style: none;
      &__row{
        display: table-row;
        border: #b7aec8 1px solid;
        border-collapse: collapse;
        border-style: inset;
        border-spacing: 0px;
        width: 100%;
        &__header{
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          font-weight: bolder;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          font-size: 12px;
        }
        &__cell{
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          vertical-align: middle;
          font-size: 12px;
          color:#8e8f96;
          &__icon{
            cursor: pointer;
          }
        }
      }
    }
    &__desktop {
      display: flex;
    }
    &__mobile {
      display: none;
      flex-direction: column;
      &__requirement{
        border: 1px solid;
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
      }
      &__details{
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 5px;
        &__icon {
          cursor: pointer;
        }
        > span {
          display: flex;
          align-items: center;
        }
      }
        &__units {
        display: flex;
        flex-direction: column;
        padding-top: 0.8rem;
        align-items: flex-end;
      }
    }
    &__document{
      position: absolute;
      top: 25px;
      right: 56px;
      &__content{
        cursor: pointer;
         a {
          // color: #8e8f96;
          text-decoration: none;
        }
         a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__check{
    position: absolute;
    right: 60px;
    top: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    > span {
      padding-right: 15px;
      display: inline-block;
    }
  }
  &__newRequest {
    padding: 2rem;
    &__buttons {
      display: flex;
      gap: 0.5rem;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
      button {
        color: #ffffff;
        &.danger {
          background-color: #c70000;
        }
        &.primary{
          background-color: rgb(48, 133, 214);
        }
      }
    }
  }
  &__noAdditional {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 0.8rem;
  }
}

.white--text{
  color: #fff;
}
.additional-card-almagro{
  background-color: $almagroBackground !important;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 20px;
  color: $almagroTipography!important;
}
.ClientAdditional {
&__container--socovesa {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
}
&__container--almagro {
  background-color: $almagroSecondary!important;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
}
&__container--pilares {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
}
&__head {
  display: flex;
  justify-content: space-between;
  ._button_action {
    max-width: 300px;
    width: 40%;
  }

  &__name_container {
    display: grid;
    margin-left: 20px;
    &__name--socovesa {
      color: #53308f;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
    }
    &__name--pilares {
      color: #ff6900;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
    }
    &__name--almagro {
      color: $almagroTipography!important;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
    }
    &__subname--socovesa {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px;
    }
    &__subname--pilares {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px;
    }
    &__subname--almagro {
      font-family: 'Montserrat';
      color: $almagroTipography!important;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px;
    }
  }
}
&__descripcion--socovesa {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68;
  &.__center{
    text-align: center;
  }
  &.commnet-variant{
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb;
  }
}
&__descripcion--almagro {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: $almagroTipography!important;
  &.__center{
    text-align: center;
  }
  &.commnet-variant{
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb;
  }
}
&__descripcion--pilares {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68;
  &.__center{
    text-align: center;
  }
  &.commnet-variant{
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb;
  }
}
&__data_container {
  display: grid;
}
&__data {
  font-family: 'HelveticaNeue';
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.01px;
  color: #bebfc5;
  margin-bottom: 7px;
}
&__data__detail--socovesa {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px;
}
&__data__detail--pilares {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px;
}
&__data__detail--almagro {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: $almagroTipography!important;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px;
}
&__Line {
  height: 1px;
  background-color: #bebfc5;
  margin-left: 30px;
  margin-right: 30px;
}

&__contact {
  display: grid;
  &__title {
    font-family: 'HelveticaNeue';
    letter-spacing: -0.02px;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    &--socovesa {
      color: #303446;
    }
    &--pilares {
      color: #303446;
    }
    &--almagro {
      color: $almagroTipography!important;
    }
  }
  &__text {
    font-family: 'HelveticaNeue';
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.48px;
    &--socovesa {
      color: #4a4d68;
    }
    &--pilares {
      color: #4a4d68;
    }
    &--almagro {
      color: $almagroTipography!important;
    }
  }
  &__button {
    color: #ffffff !important;
    width: 40%;
    padding: 10px !important;
    font-weight: bold !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    &--socovesa {
      background-color: #53308f !important;
    }
    &--almagro {
      background-color: #ee3424 !important;
    }
    &--pilares {
      background-color: #ff6900 !important;
    }
  }
}
}

.status-switch {
.Mui-checked {
  color: green;
}
}
@media only screen and (max-width: 768px) {
  .ClientAdditional {
    &__head {
      padding: 106px 0 0;
      flex-direction: column;
      align-items: center;
      &__name {
        &_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          &__name {
            font-size: 1.5rem;
            &--almagro {
              color: #ff6900;
            }
            &--socovesa {
              color: #53308f;
            }
            &--pilares {
              color: #ff6900;
            }
          }
          &__subname {
            margin-top: 2px;
            &--almagro {
            }
            &--socovesa {
            }
            &--pilares {
            }
          }
        }
      }
      ._button_action {
        width: 100%;
        text-decoration: none;
      }
    }
    &__contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        text-align: left;
        align-self: flex-start;
      }
    }
    &__desktop {
      display: none;
    }
    &__mobile{
      display: block;
    }
  }
  .ActionDetail {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .ClientAdditional {
    &__desktop {
      display: flex;
    }
    &__mobile{
      display: none;
    }
  }
}

.selector {
.MuiSelect-select.MuiSelect-selectMenu {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
}
