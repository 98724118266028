@import '../../scss/variables';

.MessageItem {
  display: flex;
  padding: 30px 20px 30px 0px;
  background-color: inherit;
  flex-direction: column;
  padding: 40px 40px;
  &--almagro {
    border-bottom: 1px solid #3c3c3c;
    &--read{
      background-color: $almagroBackground;
      // color: #888990;
    }
  }
  &--pilares {
    border-bottom: 1px solid #d8d8d8;
    &--read{
      background-color: #f4f4f5;
      // color: #bebfc5;
    }
  }
  &--socovesa {
    border-bottom: 1px solid #d8d8d8;
    &--read{
      background-color: #f4f4f5;
      // color: #bebfc5;
    }
  }
  &--empresas {
    border-bottom: 1px solid #d8d8d8;
    &--read{
      background-color: #f4f4f5;
      // color: #bebfc5;
    }
  }
  &__resume {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    &__button{
      color: inherit!important;
    }
    &__title{
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: none;
      font-weight: initial;
      text-align: left;
      margin-left: 10px;
    }
    &__date{
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: none;
      font-weight: initial;
    }
    &__from {
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 0 0px 0 30px;
      position: relative;
      font-size: 1rem;
      text-transform: none;
      text-align: left;
      &--almagro {
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $almagroPrimary;
            margin-right: 10px;
          }
        }
      }
      &--socovesa {
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $socovesaPrimary;
            margin-right: 10px;
          }
        }
      }
      &--pilares {
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $pilaresPrimary;
            margin-right: 10px;
          }
        }
      }
      &--empresas {
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: #0093ca;
            margin-right: 10px;
          }
        }
      }
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 30px;
    margin: 30px 0 0;
    transition: width 600ms ease-out, height 600ms ease-out;
    &--almagro {
      border-top: 1px solid #3c3c3c;
    }
    &--pilares {
      border-top: 1px solid #d8d8d8;
    }
    &--socovesa {
      border-top: 1px solid #d8d8d8;
    }
    &--empresas {
      border-top: 1px solid #d8d8d8;
    }
    &__title {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin: 0;
      text-transform: uppercase;
    }
    &__name{
      font-size: 16px;
      letter-spacing: -0.02px;
      margin: 0 0 4px;
    }
    &__subtitle{
      letter-spacing: -0.02px;
      margin: 0 0 29px;
    }
    &__text{
      font-size: 16px;
      letter-spacing: -0.02px;
      color: #303446;
    }
    &__body {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 10px 0 40px;
      position: relative;
      &__message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 85px;
      }
      &--almagro {
        &:not(:last-of-type){
          border-bottom: 1px solid #3c3c3c;
        }
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -25px;
            top: 35px;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $almagroPrimary;
            margin-right: 10px;
          }
        }
      }
      &--pilares {
        &:not(:last-of-type){
          border-bottom: 1px solid #d8d8d8; 
        }
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -25px;
            top: 35px;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $pilaresPrimary;
            margin-right: 10px;
          }
        }
      }
      &--socovesa {
        &:not(:last-of-type){
          border-bottom: 1px solid #d8d8d8; 
        }
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -25px;
            top: 35px;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $socovesaPrimary;
            margin-right: 10px;
          }
        }
      }
      &--empresas {
        &:not(:last-of-type){
          border-bottom: 1px solid #d8d8d8; 
        }
        &--notRead{
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -25px;
            top: 35px;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: #0093ca;
            margin-right: 10px;
          }
        }
      }
      &__from{
        letter-spacing: -0.02px;
        color: #303446;
        font-weight: bold;
        margin: 0 0 4px;
      }
    }
    &__button {
      width: 180px;
    }
  }
  &__response{
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 768px) {
  .MessageItem{
    padding: 0;
    &__detail{
      padding: 4vh 5vh;
      &__body{
        padding: 0;
        &__message{
          margin-right: 0;
        }
      }
    }
    &__resume{
      padding: 4vh 5vh;
      &__from{
        padding: 0;
        &--empresas {
          &--notRead{
            &:before {
              left: -3.5vh;
            }
          }
        }
        &--almagro {
          &--notRead{
            &:before {
              left: -3.5vh;
            }
          }
        }
        &--pilares {
          &--notRead{
            &:before {
              left: -3.5vh;
            }
          }
        }
        &--socovesa {
          &--notRead{
            &:before {
              left: -3.5vh;
            }
          }
        }
      }
      &__title{
        margin: 0;
      }
      &__collapseButton{
        display: flex;
        align-items: center;
      }
    }
  }
}
