@import '../../scss/variables';

.ProjectItem {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative;
  flex: 0 0 100%;
  margin: 15px 0;
  &:only-child{
    width: 100%;
  }
  &--almagro {
    background-color: $almagroSecondary;
    color: $almagroTipography;
  }
  &--socovesa {
    background-color: $white;
    color: #303446;
  }
  &--pilares {
    background-color: #ffffff;
    color: #303446;
  }
  &--gallery {
    display: flex;
    flex: initial;
    width: 100%;
    padding: 20px 50px;
    align-items: center;
    justify-content: space-between;
    &__number{
      margin-right: 30%;
    }
  }
  &__header {
    display: flex;
    padding: 20px;
    flex-direction: column;
    &__imageLogo {
      display: flex;
      margin-right: 15px;
      padding-top: 5px;
      align-content: center;
    }
    &__grid {
      display: grid;
      grid-template-columns: 0.1fr 4fr 1fr
    }
    &__name {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      letter-spacing: -0.02px;
      text-transform: uppercase;
    }
    &__apartments {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: -0.01px;
      margin: 5px 0 0;
      &--almagro {
        color: $almagroTipography;
      }
      &--socovesa {
        color: #bebfc5;
      }
      &--pilares {
        color: #bebfc5;
      }
      &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        margin-right: 5px;
        border-radius: 20px;
        height: 20px;
        width: 20px;
        &--almagro {
          color: $white;
          background-color: $almagroPrimary;
        }
        &--socovesa {
          color: $white;
          background-color: $socovesaPrimary;
        }
        &--pilares {
          color: $white;
          background-color: $pilaresPrimary;
        }
      }
    }
    &__button {
      position: absolute;
      right: 20px;
      cursor: pointer;
      top: 20px;
    }
  }
  &__body {
    &__image {
      width: 100%;
      // max-width: 870px;
      max-height: 300px;
    }
  }
  &__footer {
    padding: 10px 50px;
    &__label {
      display: block;
      margin: 0px 0px 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.01px;
    }
  }
}
