.Toolkit{
  padding: 20px 0;
  &__buttonShowCase{
    padding: 10px;
  }
  &__inputShowCase{
    > * { 
      margin: 0 10px!important;
    }
  }
}
