@import '../../../../scss/variables';

.UnitList{
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  &__container{
    display: grid;
    align-items: center;
    padding: 0px 20px;
    border-radius: 5px;
    grid-template-columns: 1fr;
    justify-content: start;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 10px 0px;
      &__title-pilares {
        color: #ff6900;
      }
      &__title-socovesa {
        color: #53308f;
      }
      &__title-almagro {
        color: $almagroTipography !important;
      }
    }
    &__unit {
      display: inline-block;
      text-transform: capitalize;
    }
    &__body {
      margin: 5px 0px;
      display: grid;
      grid-template-columns: 40% 60%;
      grid-auto-flow: row;
      align-items: center;
      @media only screen and (max-width: 960px) {
        &__address {
          display: none;
        }
        flex-direction: column;
        grid-template-columns: 100%;
        width: 100%;
      }
      & --almagro {
        background-color: $almagroSecondary !important;
        color: $almagroTipography !important;
      }
    }
    &__cardContainer {
      padding: 0px 46px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  &__expansionPanelDetails{
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px;
  }
}
