.ProjectDetail{
  padding: 25px 30px;
  &__projectName{
    color: #003a63!important;
  }
  &__expansionPanelSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__check{
      position: absolute;
      right: 50px;
      top: 12px;
    }
  }
  &__title{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    margin: 43px 0 25px;
  }
}
