@import '../../scss/variables';

.MessageList {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative;
  &--almagro {
    background-color: $almagroSecondary;
    color: $almagroTipography;
  }
  &--socovesa {
    background-color: $white;
  }
  &--pilares {
    background-color: $white;
  }
  &--empresas {
    background-color: $white;
  }
  &__collapseButton{
    color: inherit!important;
    position: absolute!important;
    right: 20px;
    border-radius: 50%!important;
    border: 2px solid currentColor!important;
    transform: rotate(180deg);
    &__active{
      transform: none;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &--collapse{
      flex-direction: column;
      align-items: start;
    }
    &__title {
      margin: 0;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      text-transform: uppercase;
      &__notRead{
        &::before{
          content: '';
          display: inline-block;
          position: absolute;
          left: -20px;
          top: 6px;
          width: 15px;
          height: 15px;
          border-radius: 7.5px;
          background-color: #0093ca;
          margin-right: 10px;
        }
        left: 20px;
        font-weight: bold;
        position: relative;
      }
    }
    &__notRead {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.02px;
       margin-right: 100px;
       &__number{
         border-radius: 50%;
         padding: 3px 6px;
         &--empresas{
           color: $white;
           background-color: $empresasSecondary;
         }
       }
    }
    &__colapse{
      &.MuiCollapse-entered{
        width: 100%;
      }
      &__writeMessageButton{
        padding: 15px 35px!important;
        margin: 0px 0 0px 20px!important;
      }
      &__tabContainer{
        color: inherit;
        display: flex;
        flex-direction: row;
        margin: 40px 0 0;
        width: 100%;
        &--almagro{
          color: $almagroTipography;
        }
      }
      &__mobile{
        display: none;
      }
    }
  }
  &__body{
    &__list{
      padding: 0;
      box-shadow: 0 2px 8px 0 rgba(103, 105, 119, 0.4);
    }
  }
}

.MessagePagination{
    color: inherit;
    display: flex;
    margin-left: auto;
    align-items: center;
    &__label{
      font-size: 12px;
      color: inherit;
      margin-right: 20px;
    }
    &__icon{
      color: inherit;
      margin-right: 20px;
    }
}

@media only screen and (max-width: 768px) {
  .MessageList{
    &__header{
      flex-direction: column;
      align-items: flex-start;
      &__colapse{
        display: none;
        &__mobile{
          display: flex;
          flex-direction: column;
          padding: 30px 0 0;
          &__writeMessageButton{

          }
        }
      }
    }
    &__body{
      &__tabPanel{
        padding: 0!important;
      }
      &__list{
        padding: 0;
      }
    }
  }
}

