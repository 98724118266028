.LoginContainer{
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
  &--socovesa{
    border-bottom: 10px solid #53308f;
  }
  &--empresas{
    border-bottom: 10px solid #0093ca;
  }
  &--pilares{
    border-bottom: 10px solid #ff6900;
  }
  &--almagro{
    border-bottom: 8px solid #ee3424;
  }
  &__container{
    box-sizing: border-box;
    height: calc(100vh - 12px);
  }
}
