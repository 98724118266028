.ProjectList{
  width: 100%;
  &____switch{
    position: absolute;
  }
  &__list{
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 1rem;
  }
  &__others{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__otherProjectsAccordion{
    width: 100%;
    background-color: rgba(0,0,0,0.03) !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    margin: 20px 0px ;
  }

  &__subsidiaryNames{
    &--almagro {
      margin: 1rem 0 0 0.7rem;
      color: #ffffff;
    }
    &--socovesa {
      margin: 1rem 0 0 0.7rem;
      color: #303446;
    }
    &--pilares {
      margin: 1rem 0 0 0.7rem;
      color: #303446;
    }
  }
}
