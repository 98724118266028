.RecoverPassword{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: left;
  height: 100%;
  &--almagro{
    background-color: white;
    color: black;
  }
  &__success{
    display: flex;
    flex-direction: column;
    text-align: center;
    &__icon{
      display: flex;
      align-self: center;
      justify-self: center;
      margin: 0 0 10px;
    }
    &__span{
      &:last-of-type{
        margin: 0 0 30px;
      }
    }
  }
  &__container{
    display: flex;
    flex-direction: column;
    height: 100%;
    &__logo{
      padding: 80px 0 80px;
      display: block;
      margin: 0 auto;
    }
    &__title{
      margin: 0;
      &:last-of-type{
        margin: 0 0 20px;
      }
    }
    &__form{
      &__buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        justify-items: center;
        margin: 35px 0 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .RecoverPassword{
    padding: 0 20px 60px;
    &__container{
      &__title{
        font-size: 24px;
      }
      &__form{
        &__buttons{
          flex-direction: column;
          > * {
            margin-bottom: 20px!important;
            width: 100%!important;
          }  
        }
      }
    }
  }
}
